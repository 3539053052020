import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import GatewayContext from './GatewayContext';

function removeByKey(keyToRemove) {
  return (removeFrom) => {
    const clone = { ...removeFrom };
    delete clone[keyToRemove];
    return clone;
  };
}

function getDestNameAndChildId(gatewayId) {
  return gatewayId.split('##');
}

function verifyDestNameValid(destName) {
  if (destName.indexOf('##') !== -1) {
    throw new Error('dest names should not have ##');
  }
}

function GatewayProvider({ children }) {
  const [, setCurrentId] = useState(0);
  const [gateways, setGateways] = useState({});
  const [containers, setContainer] = useState({});

  const addGateway = (destName, child, setGatewayId) => {
    verifyDestNameValid(destName);

    setCurrentId((prevCurrentId) => {
      const gatewayId = `${destName}##${prevCurrentId}`;
      setGateways(prevGateways => ({
        ...prevGateways,
        [gatewayId]: child,
      }));
      setGatewayId(gatewayId);
      return prevCurrentId + 1;
    });
  };

  const getContainerChildren = useCallback(name => Object.keys(gateways)
    .map((id) => {
      const [destName] = getDestNameAndChildId(id);
      if (destName !== name) {
        return null;
      }
      return gateways[id];
    }), [gateways]);

  const removeGateway = useCallback((gatewayId) => {
    setGateways(removeByKey(gatewayId));
    const [destName] = getDestNameAndChildId(gatewayId);
    if (containers[destName]) {
      containers[destName](getContainerChildren(destName));
    }
  }, [setGateways, containers]);

  const updateGateway = useCallback((gatewayId, child) => {
    setGateways(prevGateways => ({
      ...prevGateways,
      [gatewayId]: child,
    }));
  }, [setGateways]);

  const addContainer = useCallback((name, setContainerChildren) => {
    verifyDestNameValid(name);
    setContainer(prevContainers => ({
      ...prevContainers,
      [name]: setContainerChildren,
    }));
  }, [setContainer]);

  const removeContainer = useCallback((name) => {
    setContainer(removeByKey(name));
  }, [setContainer]);

  const setState = useMemo(() => ({
    addGateway,
    removeGateway,
    updateGateway,
    addContainer,
    removeContainer,
    getContainerChildren,
  }), [
    addGateway,
    removeGateway,
    updateGateway,
    addContainer,
    removeContainer,
    getContainerChildren,
  ]);

  return (
    <GatewayContext.Provider value={setState}>
      {children}
    </GatewayContext.Provider>
  );
}

GatewayProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GatewayProvider;
